import 'flickity/dist/flickity.pkgd.js';
import { gsap } from 'gsap';
import { Flip } from 'gsap/Flip';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Draggable } from 'gsap/Draggable';

gsap.registerPlugin(Flip,ScrollTrigger,Draggable);

export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS

    // Check if a specific id is in view and fire the CountUp Function
    var counter = 0;
    const numberOfStats = $('.home-our-partner-section .counter-card-wrp').length+1;

    var observer = new IntersectionObserver(function(entries) {
      if(entries[0].isIntersecting === true)
        if (counter == 0) {
          setTimeout(function(){
            counter++;
            for (let i = 1; i < numberOfStats; i++) {
              document.querySelector('#final-'+ i).classList.remove('d-none');
              document.querySelector('#initial-'+ i).classList.add('d-none');
            }
            countUp();
          }, 2000);
        }
    }, { threshold: [0.1] });

    observer.observe(document.querySelector('.home-our-partner-section'));

    homeImageSlider();
    bannerSlider();
    verticalSlider();
    featuresHover();
    productSectionAnimate();
    verticalViewportAnimation();
  },
};

$(window).on('resize', function () {
  $('.gallery-wrp, .home-banner-slider, .home-banner-image-slider-wrp').flickity('resize');
  verticalViewportAnimation();
});

$(window).on('load', function () {
  $('.gallery-wrp, .home-banner-slider, .home-banner-image-slider-wrp').flickity('resize');
  verticalViewportAnimation();
});

function productSectionAnimate(){
  var $myBox = $('.product-item-wrp .item-inner-wrp');
  var offset = $myBox.offset();
  var xValue = offset.left;
  var widthValue = $('.product-item-wrp .item-inner-wrp').outerWidth();
  var viewportHeight = $('.home-products-section').outerHeight();
  var scrollHeight = $('.home-products-section').outerHeight();

  if (matchMedia('screen and (min-width: 768px)').matches) {
    gsap.from('.card-item-1 .item-inner-wrp', {
      x: -(xValue + widthValue),
      y: -(viewportHeight * 0.5),
      rotation: 12,
    });

    gsap.from('.card-item-2 .item-inner-wrp', {
      x: 0,
      y: -(viewportHeight * 0.7),
      rotation: -18,
    });

    gsap.from('.card-item-3 .item-inner-wrp', {
      x: (xValue + widthValue),
      y: -(viewportHeight * 0.5),
      rotation: 18,
    });
  }

  else {
    gsap.from('.card-item-1 .item-inner-wrp', {
      x: (xValue + widthValue),
      y: -20,
      rotation: 12,
    });

    gsap.from('.card-item-2 .item-inner-wrp', {
      x: -(xValue + widthValue),
      y: -20,
      rotation: -18,
    });

    gsap.from('.card-item-3 .item-inner-wrp', {
      x: (xValue + widthValue),
      y: -20,
      rotation: 18,
    });
  }

  if (matchMedia('screen and (min-width: 1199px)').matches) {
    gsap.to('.product-item-wrp .item-inner-wrp', {
      scrollTrigger: {
        trigger: '.home-products-section',
        toggleActions: 'play reverse play pause',
        start: 'bottom 100%',
        end: 'bottom 60%',
        scrub: 2,
      },
      x: 0,
      y: 0,
      rotation: 0,
      opacity: 1,
    });
  } else if ((matchMedia('screen and (min-width: 768px) and (max-width: 1199px)').matches)) {
    gsap.to('.product-item-wrp .item-inner-wrp', {
      scrollTrigger: {
        trigger: '.home-products-section',
        toggleActions: 'play reverse play pause',
        start: 'bottom 100%',
        end: 'bottom 80%',
        scrub: 2,
      },
      x: 0,
      y: 0,
      rotation: 0,
      opacity: 1,
    });
  }  else {
    gsap.to('.card-item-1 .item-inner-wrp', {
      scrollTrigger: {
        trigger: '.card-item-1',
        toggleActions: 'play reverse play pause',
        start: 'top 100%',
        end: 'bottom 80%',
        scrub: 2,
      },
      x: 0,
      y: 0,
      rotation: 0,
    });

    gsap.to('.card-item-2 .item-inner-wrp', {
      scrollTrigger: {
        trigger: '.card-item-2',
        toggleActions: 'play reverse play pause',
        start: 'top 100%',
        end: 'bottom 80%',
        scrub: 2,
      },
      x: 0,
      y: 0,
      rotation: 0,
    });

    gsap.to('.card-item-3 .item-inner-wrp', {
      scrollTrigger: {
        trigger: '.card-item-3',
        toggleActions: 'play reverse play pause',
        start: 'top 100%',
        end: 'bottom 80%',
        scrub: 2,
      },
      x: 0,
      y: 0,
      rotation: 0,
    });
  }

  if (matchMedia('screen and (min-width: 1199px)').matches) {
    gsap.to('.home-products-section', {
      scrollTrigger: {
        pin: '.home-products-section',
        end: '+=' + scrollHeight*0.7,
        pinSpacing: true,
      },
    });
  }

  else {
    gsap.to('.home-products-section', {
      scrollTrigger: {
        toggleActions: 'play reverse play pause',
        pin: true,
        scrub: 3,
        duration: 3,
        pinSpacing: true,
      },
    });
  }
}

function countUp() {
  $('.count').each(function () {
    $(this)
      .prop('Counter', 0)
      .animate(
        {
          Counter: $(this).text(),
        },
        {
          duration: 2000,
          easing: 'swing',
          step: function (now) {
            now = Number(Math.ceil(now)).toLocaleString('en');
            $(this).text(now);
          },
        }
      );
    }
  );
}

function bannerSlider() {
  $('.home-banner-slider').flickity({
    contain: true,
    lazyLoad: true,
    groupCells: 1,
    cellAlign: 'left',
    prevNextButtons: false,
    pageDots: false,
    watchCSS: true,
    autoPlay: true,
    pauseAutoPlayOnHover: true,
  })
}

function verticalSlider() {
  $('.home-verticals-section .gallery-wrp').flickity({
    cellAlign: 'left',
    pageDots: true,
    prevNextButtons: false,
    watchCSS: true,
    wrapAround: true,
  })
}

function featuresHover() {
  $('.verticals-wrp-inner').each(function () {
    var $img = $(this).find('.icon-wrp');
    $img.css('opacity', '0');

    $(this).mousemove(function(e) {
      $img.stop(1, 1).css('opacity', '1');
      $img.offset({
        top: e.pageY - ($img.outerHeight()/2),
        left: e.pageX - ($img.outerWidth()/2),
      });
    }).mouseleave(function() {
        // $img.fadeOut();
        $img.css('opacity', '0');
    });
  });
}
function verticalViewportAnimation() {
  if (matchMedia('screen and (max-width: 576px)').matches) {
    const $sections = $('.verticals-wrp-inner');

    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.7, // 70% visibility
    };

    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                $(entry.target).addClass('selected');
            } else {
                $(entry.target).removeClass('selected');
            }
        });
    }, options);

    $sections.each(function() {
        observer.observe(this);
    });
  }
}

function homeImageSlider() {
  $('.home-banner-section .home-banner-image-slider-wrp.slider').flickity({
    pageDots: true,
    prevNextButtons: false,
    wrapAround: true,
  })
}
